"use client";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HomeMenu from './store/home-menu';
import LoginMenu from './store/login-menu';

class FloatMenu extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }

  render(){
    const { floatMenuId, appTexts, currentLanguage, setFloatMenuId, logged } = this.props;
    return (
      <div className="FloatMenu" >
        {floatMenuId === 'MenuHome' && (
          <HomeMenu
            appTexts={appTexts}
            currentLanguage={currentLanguage}
            setFloatMenuId={setFloatMenuId}
          />
        )}
        {floatMenuId === 'MenuLogin' && (
          <LoginMenu
            logged={logged}
            appTexts={appTexts}
            currentLanguage={currentLanguage}
            setFloatMenuId={setFloatMenuId}
          />
        )}
      </div>
    );
  }

}

/*
const mapDispatchToProps = dispatch => ({
  // APP TEXTS
  getAppTexts: () => dispatch(appInfoActions.getAppTexts()),
  setCurrentLanguage: language => dispatch(appInfoActions.setCurrentLanguage(language)),
});
*/

const mapStateToProps = state => ({
  /* user */
  // logged: state.user.logged,
  /* ui */
  floatMenuId: state.ui.floatMenuId,
  // appTexts: state.appTexts.texts,
  // currentLanguage: state.appTexts.currentLanguage,
});


FloatMenu.propTypes = {
  appTexts: PropTypes.array.isRequired,
  currentLanguage: PropTypes.string,
}

FloatMenu.defaultProps = {
  currentLanguage: 'es',
}

export default connect(
  mapStateToProps,
  null,
)(FloatMenu);
